import "react-accessible-accordion/dist/fancy-example.css";
import React, { useEffect, useState } from "react";
import Header from "../../Wrapper/Header";
import Loader from "../Home/Loader";
import ContactComponent from "./ContactComponent";
import FooterComponent from "../Home/FooterComponent";
import Button from "react-bootstrap/Button";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import PropTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import "react-accessible-accordion/dist/fancy-example.css";

const SSSComponent = (props) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  return (
    <>
      <Loader loading={loading} />
      <div className="row justify-content-center py-0 h-10vh">
        <div className="zi justify-content-center position-fixed p-0">
          <div className="logoSoho" style={{ backgroundColor: "white" }}>
            <a href="/">
              <img
                className="logoSoho2"
                src="/assets/images/logoSoho.svg"
                alt="soho demir organik"
              />
            </a>
          </div>
        </div>
      </div>
      <section className="">
        <div className="backImg4 d-flex justify-content-start">
          <div className="title d-flex align-items-center ">
            <span className="main-title p-3">{props.strings["kvkk"]}</span>
          </div>
        </div>
      </section>{" "}
      <Header />
      <div className="container d-flex justify-content-center p-3">
        <div className="row d-flex justify-content-center">
          <div classname="col-lg-10 col-md-6 d-flex justify-content-center mt-3">
            <p className="mt-5" style={{ textAlign: "center" }}>
              <strong>
                KİŞİSEL VERİLERİN KORUNMASI ve İŞLENMESİ HAKKINDA GENEL
                AYDINLATMA METNİ
              </strong>
            </p>
            <p style={{ textAlign: "center" }}>
              <strong>-(WEB)-</strong>
            </p>
            <p style={{ textAlign: "center" }}>&nbsp;</p>
            <p>
              Şirketimiz
              <strong>
                &nbsp; DEMİR ORGANİK TARIM İTHALAT İHRACAT LİMİTED ŞİRKETİ&nbsp;
              </strong>
              6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) uyarınca
              Veri Sorumlusudur.
            </p>
            <p>&nbsp;</p>
            <p>
              Veri Sorumlusuna, KVKK’nun 10. Maddesi uyarınca “Veri Sorumlusunun
              Aydınlatma Yükümlülüğü” görevi yüklenmiştir. Bu kapsamda amacımız;
              kişisel verilerinizin toplanma şekilleri, işlenme amaçları,
              işlemenin hukuki nedenleri ve haklarınız konusunda sizleri en
              şeffaf şekilde bilgilendirmektir.
            </p>
            <p>&nbsp;</p>
            <p>
              Bu aydınlatma metni, <u>Veri Sorumlusu sıfatıyla DEMİR ORGANİK</u>
              &nbsp; tarafından hazırlanmıştır.
            </p>
            <p>&nbsp;</p>
            <p>
              Bu kapsamda, öncelikle, kişisel verilerin hukuka uygun olarak
              toplanması, saklanması, işlenmesi ve paylaşılmasına ilişkin
              gizliliğinizin koruması amacıyla teknik ve idari tedbirleri
              almakta olduğumuzu ifade etmek isteriz.
            </p>
            <p>&nbsp;</p>
            <p>
              Bu aydınlatma metninde sırasıyla “kişisel verilerinizin işlenme
              amaçları, kişisel verilerinizin kimlere ve hangi amaçlarla
              aktarılabileceği, kişisel veri toplamanın yöntemi ve hukuki
              sebebi, kişisel veri sahibinin hakları ve kişisel veri sahibinin
              belirtilen haklarına ilişkin başvuru yöntemi” konusunda sizleri
              aydınlatmak amacıyla ayrıntılı açıklamalar yapılmıştır.
            </p>
            <p>&nbsp;</p>
            <p>
              <em>
                Siz değerli kişisel veri sahiplerinin bu açıklamaları okumuş
                olması aydınlatma yükümlüğümüz ve haklarınız bakımından önemli
                olacaktır…
              </em>
              <strong>
                <em> </em>
              </strong>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong> </strong>
                <strong>Kişisel Verilerin İşlenme Amaçları </strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              Kişisel verileriniz, KVKK tarafından öngörülen temel ilkelere
              uygun olarak ve KVKK’nın 5. ve 6. maddelerinde belirtilen kişisel
              veri işleme şartları ve amaçları dahilinde aşağıda örneklendirilen
              amaçlar kapsamında işlenebilecektir;
            </p>
            <p>&nbsp;</p>
            <ul>
              <li>
                Çalışan Adayı / Stajyer / Öğrenci Seçme ve Yerleştirme
                Süreçlerinin Yürütülmesi
              </li>
              <li>Çalışan Adaylarının Başvuru Süreçlerinin Yürütülmesi</li>
              <li>Çalışan Memnuniyeti ve Bağlılığı Süreçlerinin Yürütülmesi</li>
              <li>
                Çalışanlar İçin İş Akdi ve Mevzuattan Kaynaklı Yükümlülüklerin
                Yerine Getirilmesi
              </li>
              <li>
                Çalışanlar İçin Yan Haklar ve Menfaatleri Süreçlerinin
                Yürütülmesi
              </li>
              <li>Denetim / Etik Faaliyetlerinin Yürütülmesi</li>
              <li>Eğitim Faaliyetlerinin Yürütülmesi</li>
              <li>Faaliyetlerin Mevzuata Uygun Yürütülmesi</li>
              <li>Finans ve Muhasebe İşlerinin Yürütülmesi</li>
              <li>Görevlendirme Süreçlerinin Yürütülmesi</li>
              <li>Hukuk İşlerinin Takibi ve Yürütülmesi</li>
              <li>
                İç Denetim/ Soruşturma / İstihbarat Faaliyetlerinin Yürütülmesi
              </li>
              <li>İletişim Faaliyetlerinin Yürütülmesi</li>
              <li>
                İnsan kaynakları politikaları ve süreçlerinin planlanması ve
                icrası,
              </li>
              <li>İş Faaliyetlerinin Yürütülmesi / Denetimi</li>
              <li>Lojistik Faaliyetlerinin Yürütülmesi</li>
              <li>Mal / Hizmet Satın Alım Süreçlerinin Yürütülmesi</li>
              <li>
                Mal / Hizmet Satış Sonrası Destek Hizmetlerinin Yürütülmesi
              </li>
              <li>Mal / Hizmet Satış Süreçlerinin Yürütülmesi</li>
              <li>Müşteri İlişkileri Yönetimi Süreçlerinin Yürütülmesi</li>
              <li>Performans Değerlendirme Süreçlerinin Yürütülmesi</li>
              <li>Saklama ve Arşiv Faaliyetlerinin Yürütülmesi</li>
              <li>Sözleşme Süreçlerinin Yürütülmesi</li>
              <li>Stratejik Planlama Faaliyetlerinin Yürütülmesi</li>
              <li>Talep / Şikayetlerin Takibi</li>
              <li>Taşınır Mal ve Kaynakların Güvenliğinin Temini</li>
              <li>Tedarik Zinciri Yönetimi Süreçlerinin Yürütülmesi</li>
              <li>Ücret Politikasının Yürütülmesi</li>
              <li>Yetenek / Kariyer Gelişimi Faaliyetlerinin Yürütülmesi</li>
              <li>Yetkili Kişi, Kurum ve Kuruluşlara Bilgi Verilmesi</li>
              <li>Yönetim Faaliyetlerinin Yürütülmesi</li>
              <li>Ziyaretçi Kayıtlarının Oluşturulması ve Takibi</li>
              <li>Müşteri Memnuniyetine Yönelik Aktivitelerin Yürütülmesi</li>
              <li>Fiziksel Mekan Güvenliğinin Temini</li>
              <li>Bilgi Güvenliği Süreçlerinin Yürütülmesi</li>
              <li>Erişim Yetkilerinin Yürütülmesi</li>
              <li>Risk Yönetimi Süreçlerinin Yürütülmesi</li>
              <li>Ürün / Hizmetlerin Pazarlama Süreçlerinin Yürütülmesi</li>
              <li>
                Çalışanlar için İş Sağlığı / Güvenliği, İş Akdi ve Mevzuattan
                kaynaklı yükümlülüklerin yerine getirilmesi ve Ticari faaliyet
                için gerekli tüm yönetim ve organizasyon tedbirleri gibi amaçlar
                ile DEMİR ORGANİK’in icra ettiği ticari ve kurumsal
                faaliyetlerin sözleşmelere, mevzuata ve şirket politikalarına
                uygun olarak yerine getirilmesi için gerekli çalışmaların hukuka
                uygun olarak yapılması gerçekleştirilebilmesi maksadına yönelik
                amaçları.
              </li>
            </ul>
            <p>&nbsp;</p>
            <ol start={2}>
              <li>
                <strong>
                  &nbsp; Kişisel Verilerinizin Kimlere ve Hangi Amaçlarla
                  Aktarılabileceği
                </strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>Kişisel verileriniz;</p>
            <p>&nbsp;</p>
            <ul>
              <li>
                Şirketimizin yasalar karşısındaki yükümlülüklerinin ifası,
              </li>
              <li>
                İş faaliyetlerinin yerine getirilmesine ilişkin
                sorumluluklarının ifası,
              </li>
              <li>
                Mal hizmet satın alım süreçleri ile mal hizmet satış
                süreçlerinin yürütülmesi,
              </li>
              <li>
                Ticari faaliyetlerimiz çerçevesindeki strateji belirlenmesi,
              </li>
              <li>
                İnsan kaynakları, mali ve idari politikaların yürütülmesi,
              </li>
              <li>Finansal faaliyetlerimizin yürütülmesi amaçlarıyla;</li>
            </ul>
            <p>&nbsp;</p>
            <p>
              Banka ve finans kuruluşlarına, ticari faaliyetlerimiz kapsamında
              ticari ilişki içinde bulunduğumuz tüzel ve gerçek kişilere,
              yöneticilerimize, şirket görevlileri, hissedarları, çalışanları,
              iş ortaklarına, bayilerimize, hizmet alınan kişi veya üçüncü
              kişiler, danışmanlar, denetçiler, özel hukuk ya da kamu tüzel
              kişileri, şahıslar, bakanlıklar, yargı mercileri, hukuk büroları
              gibi kanunen yetkili kılınan ve bilgi paylaşımını talep eden
              otoriteler ile paylaşım nedeniyle yurt içi/yurt dışına, KVKK’nın
              8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve
              amaçları çerçevesinde aktarılabilecektir.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <ol start={3}>
              <li>
                <strong> </strong>
                <strong>
                  Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi
                </strong>
              </li>
            </ol>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <p>Kişisel verileriniz;</p>
            <p>&nbsp;</p>
            <ul>
              <li>
                Web sitemizden ya da elektronik ortamdan ilettiğiniz müracaat,
                başvuru, şikâyet öneri formu dahil olmak üzere her türlü formlar
                aracılığıyla,
              </li>
              <li>
                Veri Sorumlusuna şahsen, elden, web sitesi, elektronik posta,
                kargo, referans ve benzeri yöntemler kullanarak ilettiğiniz her
                türlü bilgi ve belgeler yoluyla,
              </li>
              <li>
                İmzalanan sözleşmeler ya da ticari faaliyetimiz gereklerince
                oluşturulan raporlama faaliyetleri vasıtasıyla,
              </li>
              <li>
                Ziyaretçi kayıt defteri, güvenlik noktalarında bulunan güvenlik
                kameraları, telefon, e-posta, faks, kurumsal yazışmalar ve
                raporlama faaliyetleri yoluyla,
              </li>
              <li>
                Hukuki yükümlülüklerimizi yerine getirmek için tarafımıza
                iletilen hukuki belge ve tebligatlar vasıtasıyla,
              </li>
              <li>
                Veri Sorumlusunun ticari faaliyetini sürdürebilmesi için icra
                edilen ticari süreçler yoluyla,
              </li>
              <li>
                İlgili mevzuat gereğince faaliyetlerimizin yürütülmesi,
                sürdürülmesi, geliştirilmesi ve denetlenebilmesi sırasında,
              </li>
              <li>
                Faaliyetlerimize yönelik tanıtım, kampanya ve benzeri süreçler
                yoluyla,
              </li>
              <li>
                Planlama, istatistik, memnuniyet anketlerinin yapılması,
                güvenliğin sağlanması, e-postalar, sosyal medya paylaşımları ve
                çalışan adaylarla gerçekleştirilen görüşmeler sırasında,
              </li>
              <li>
                Yazılı ya da elektronik özgeçmişin/başvuru formunun e-posta,
                kargo ve benzeri yöntemlerle gönderilmesi yoluyla,
              </li>
              <li>
                Tarafımıza iletilen her türlü tebligat ya da gönderi üzerindeki
                bilgilerin işleme alınması gerekliliği nedeniyle,
              </li>
              <li>
                KVKK’nın 5. ve 6. maddelerinde belirlenen şartlar dahilinde,
                ticari faaliyet sırasında kişilerin hukuki ve ticari
                güvenliğinin idamesi, finansal/mali, idari işler ve iş
                stratejilerinin belirlenmesi, denetim ve bildirim
                sorumluluklarının yerine getirilmesi gibi ticari faaliyet
                süreçlerinde uyulması gereken yükümlülüklerimizi düzenleyen
                mevzuat ve hukuki kurallar gereğince toplanmaktadır.
              </li>
            </ul>
            <p>&nbsp;</p>
            <ol start={4}>
              <li>
                <strong> </strong>
                <strong>Kişisel Veri Sahibinin Hakları</strong>
              </li>
            </ol>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <p>
              Kişisel veri sahibi olarak 6698 sayılı Kanun’un 11. Maddesinde
              düzenlenen ve aşağıda belirtilen haklarınızı kullanabilirsiniz. Bu
              kapsamdaki haklarınız:
            </p>
            <p>&nbsp;</p>
            <ul>
              <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
              <li>Kişisel veriler işlenmişse buna ilişkin bilgi talep etme,</li>
              <li>
                Kişisel verilerin işlenme amacını ve bunların amacına uygun
                kullanılıp kullanılmadığını öğrenme,
              </li>
              <li>
                Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
                üçüncü kişileri bilme,
              </li>
              <li>
                Kişisel verilerin eksik veya yanlış işlenmiş olması halinde
                bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin
                kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini
                isteme,
              </li>
              <li>
                6698 Sayılı Kanun ve ilgili diğer kanun hükümlerine uygun olarak
                işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin
                ortadan kalkması halinde kişisel verilerin silinmesini veya yok
                edilmesini isteme ve bu kapsamda yapılan işlemin kişisel
                verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
              </li>
              <li>
                İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
                analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun
                ortaya çıkmasına itiraz etme,
              </li>
              <li>
                Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                zarara uğraması halinde zararın giderilmesini talep etme.
              </li>
            </ul>
            <p>&nbsp;</p>
            <ol start={5}>
              <li>
                <strong> </strong>
                <strong>
                  Kişisel Veri Sahibinin Yukarıda Belirtilen Haklarına İlişkin
                  Başvuru Yöntemi
                </strong>
              </li>
            </ol>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <p>
              <strong>5.1</strong>. Yukarıda sıralanan KVKK’nın 11 inci
              maddesinde düzenlenen haklarınız kapsamındaki taleplerinizi,
              yazılı olarak ya da kayıtlı elektronik posta (KEP) adresi, güvenli
              elektronik imza, mobil imza veya tarafınızdan şirketimize (veri
              sorumlusuna) daha önce bildirilen ve sistemimizde kayıtlı bulunan
              elektronik posta adresini kullanmak suretiyle veya başvuru amacına
              yönelik geliştirilmiş bir yazılım ya da uygulama vasıtasıyla
              şirketimize (veri sorumlusuna) iletebilirsiniz.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>5.2</strong>. Başvuruda;
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                a) Ad, soyad ve başvuru yazılı ise imza,
                <br />
                b) Türkiye Cumhuriyeti vatandaşları için T.C. kimlik numarası,
                yabancılar için uyruğu, pasaport numarası veya varsa kimlik
                numarası,
                <br />
                c) Tebligata esas yerleşim yeri veya iş yeri adresi,
                <br />
                ç) Varsa bildirime esas elektronik posta adresi, telefon ve faks
                numarası,
                <br />
                d) Talep konusu bulunması zorunludur,
                <br />
                e) Konuya ilişkin bilgi ve belgeler başvuruya eklenir.
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <strong>5.3</strong>. Başvurunuzu,&nbsp;KVK Kanunu İlgili Kişi
              Başvuru Formu’ nu doldurarak şirketimizin “Oğuzlar Mah/Semt
              Osmanlı Cad. No: 16 B Çankaya/ANKARA” adresine yazılı ve imzalı
              olarak posta yoluyla, &nbsp;elektronik
              ortamda&nbsp;info@demirorganik.com’a göndererek veya Kayıtlı
              Elektronik Posta (KEP)
              hesabından&nbsp;demirorganik@hs01.kep.tr&nbsp;adresine
              iletebilirsiniz.
            </p>
            <p>&nbsp;</p>
            <ul>
              <li>
                <strong> </strong>Veri Sorumlusunun talebinizi yanıtlamadan önce
                kimliğinizi doğrulama hakkı saklıdır. Veri Sorumlusu talebinizi
                niteliğine göre en kısa sürede ve en geç otuz gün içinde
                sonuçlandıracaktır. Bu itibarla, Veri Sorumlusu ile
                paylaştığınız bilgilerin doğru olması sahip olduğunuz
                haklarınızı kullanabilmeniz için gerekli olup hatalı bilgi
                verilmesinden doğacak sorumluluk Veri Sorumlusuna ait
                olmayacaktır. Ancak, işlemin ayrıca bir maliyeti gerektirmesi
                halinde, KVK Kurulu’nca belirlenen tarifedeki ücreti
                tarafınızdan talep etme hakkımız mevcuttur.
              </li>
            </ul>
            <div className="row" style={{ paddingBottom: "10px" }}>
              <div className="grid-contact">
                <i className="fa fa-envelope-o" />
                <div className="contact-content">
                  <p>
                    <strong>KEP</strong>

                    <br />
                    <a href="mailto:demirorganik@hs01.kep.tr">
                      demirorganik@hs01.kep.tr
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <p className="text-center">
              <strong>İLGİLİ KİŞİ BAŞVURU FORMU </strong>
            </p>
            <a
              className="d-flex justify-content-center"
              href="/assets/certificate/DemirOrganik_ilgili-kisi-basvuru-formu.pdf"
              target="_blank"
            >
              <Button
                className="form-submit rectangle-button green medium "
                name="submit"
                type="submit"
                id="submit"
                defaultValue="Send"
              >
                Forma Ulaşmak İçin Tıklayın
              </Button>
              <h1
                style={{
                  color: "white",
                  margin: "0px",
                  padding: "0px",
                  fontSize: "1px",
                }}
              >
                {props.strings["kvkk"]}
              </h1>
            </a>
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

SSSComponent.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(SSSComponent));
