import React, { useEffect, useState } from "react";
import Header from "../../Wrapper/Header";
import Loader from "../../Component/Home/Loader";
import FooterComponent from "../../Component/Home/FooterComponent";
import BlogComponent from "../../Component/Home/BlogComponent";
import ProductSlider from "../../Component/Home/ProductSlider";
import PropTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

const ProductHomeComponent = (props) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  return (
    <>
    <Helmet>
            <title>{`SOHO Fertilizer | ${props.strings["urunler"]}`}</title>
          <meta property="title" content={`SOHO Fertilizer | ${props.strings["urunler"]}`} />

        <meta property="og:type" content="article" />
        <meta property="og:title" content={`SOHO Fertilizer | ${props.strings["urunler"]}`}/>
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content={"https://www.sohofertilizer.com/products"}
        />

        <link
          rel="canonical"
          href={"https://www.sohofertilizer.com/products"}
        />
      
      </Helmet>
      <Loader loading={loading} />
      <div className="row justify-content-center py-0 h-10vh">
        <div className="zi justify-content-center position-fixed p-0">
          <div className="logoSoho" style={{ backgroundColor: "white" }}>
            <a href="/">
              <img
                className="logoSoho2"
                src="/assets/images/logoSoho.svg"
                alt="soho fertilizer damlama gübreleri demir organik"
              />
            </a>
          </div>
        </div>
      </div>
      <Header />
      <section id="about" className="product-home about-us  ">
        <img
          className="video-slider"
          src="/assets/images/soho_drone.webp"
          alt="soho fertilizer damlama gübreleri demir organik"
        ></img>

        {/* <div className="backImg d-flex justify-content-start">
          <div className="title d-flex align-items-center ">
            <span className="main-title p-3">
              {props.strings["hakkkimizda_2"]}
            </span>
            <span className="slash-icon">/ </span>
            <div>
              <img src="/assets/images/su.png" className="banner_icon" alt="" />
            </div>
          </div>
        </div> */}
        <div className="row" style={{ width: "1300px" }}>
          <div className="col-lg-6 col-md-12 col-sm-12 ">
            <ProductSlider />
          </div>{" "}
          <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
            <div class=" product-bottom">
              <h1>
                <div class="scroller-2">
                  <span>
                    {props.strings["text_1"]}
                    <br />
                    {props.strings["text_2"]}
                    <br />
                    {props.strings["text_3"]}
                  </span>
                </div>
              </h1>
            </div>
          </div>
        </div>
      </section>{" "}
      <a href="#" id="scroll-top" className="scroll-top">
        <i className="fa fa-angle-up" />
      </a>{" "}
      <div>
        <FooterComponent />
      </div>
    </>
  );
};

ProductHomeComponent.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(ProductHomeComponent));
