import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import {
  Autoplay,
  Pagination,
  Parallax,
  Navigation,
  EffectCoverflow,
} from "swiper";
// import "swiper/swiper.scss";
// import "swiper/components/navigation/navigation.scss";
// import "swiper/components/pagination/pagination.scss";
// import "swiper/components/effect-coverflow/effect-coverflow.scss";
import SwiperCore from "swiper";
SwiperCore.use([Navigation, Pagination, EffectCoverflow]);

const test2 = () => {
  return (
    <div>
      <section className="swiper-container">
        <Swiper
          init={false}
          loop={true}
          speed={800}
          slidesPerView={1}
          spaceBetween={0}
          centeredSlides={true}
          effect="coverflow"
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 60,
            modifier: 1,
            slideShadows: true,
          }}
          grabCursor={true}
          modules={[Parallax, Pagination, Navigation, EffectCoverflow]}
          parallax={true}
          pagination={{ el: ".swiper-pagination", clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          breakpoints={{
            1000: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            767: {
              slidesPerView: 2,
              spaceBetween: 80,
            },
          }}
        >
          <div className="swiper-wrapper">
            <SwiperSlide>
              <div className="swiper-slide">
                <iframe
                  width={883}
                  height={400}
                  src="https://www.youtube.com/embed/-xwQ4SlJe9w"
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
                <div className="swiper-content">
                  <p
                    className="swiper-title"
                    data-swiper-parallax="-30%"
                    data-swiper-parallax-scale=".7"
                  >
                    Aşkın Ateş
                  </p>
                  <span className="swiper-caption" data-swiper-parallax="-20%">
                    Expert Talks
                  </span>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="swiper-slide">
                <iframe
                  width={883}
                  height={400}
                  src="https://www.youtube-nocookie.com/embed/m67DXSAcsDE"
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
                <div className="swiper-content">
                  <p
                    className="swiper-title"
                    data-swiper-parallax="-30%"
                    data-swiper-parallax-scale=".7"
                  >
                    Erol KURBAN
                  </p>
                  <span className="swiper-caption" data-swiper-parallax="-20%">
                    Experts Talks
                  </span>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="swiper-slide">
                <iframe
                  width={883}
                  height={400}
                  src="https://www.youtube.com/embed/UMRgYrJm24s"
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
                <div className="swiper-content">
                  <p
                    className="swiper-title"
                    data-swiper-parallax="-30%"
                    data-swiper-parallax-scale=".7"
                  >
                    Nadir OKUTUCU
                  </p>
                  <span className="swiper-caption" data-swiper-parallax="-20%">
                    Expert Talks
                  </span>
                </div>
              </div>
            </SwiperSlide>
          </div>
          <div className="swiper-pagination" />
          <div className="swiper-button-prev swiper-button-white" />
          <div className="swiper-button-next swiper-button-white" />
        </Swiper>
      </section>
    </div>
  );
};

export default test2;
