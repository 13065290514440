import React from "react";
import PropTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
const DashAbout = (props) => {
  return (
    <>
      <section id="about" className="about-us mt-5 ">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center ">
            <div className=" d-flex justify-content-center mb-3">
              <div class="">
                <h1>
                  <div class="scroller">
                    <span>
                      {props.strings["text_1"]}
                      <br />
                      {props.strings["text_2"]}
                      <br />
                      {props.strings["text_3"]}
                    </span>
                  </div>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container d-flex justify-content-center p-3">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-5 col-md-6">
              <img
                data-aos="fade-down"
                data-aos-duration="1500"
                data-aos-delay="00"
                src="/assets/images/8-min.webp"
                className="shadow iconDetails"
                alt="soho fertilizer damlama gübreleri demir organik"
              />
            </div>
            <div className="col-lg-6 col-md-6 d-flex justify-content-center align-items-center mt-3">
              <dl>
                {" "}
                <dd className="about container-fluid">
                  {props.strings["home_hakkimizda"]}
                  <br />
                  {props.strings["home_hakkimizda_2"]}
                </dd>
              </dl>

              <h1
                style={{
                  color: "white",
                  margin: "0px",
                  padding: "0px",
                  fontSize: "1px",
                }}
              >
                {props.strings["anasayfa"]}
              </h1>
            </div>
          </div>
        </div>
        {/* <div className="row d-flex justify-content-center mt-5">
          <div className="col-5 d-flex justify-content-center">
            <img
              style={{ height: "8vh" }}
              src={"/assets/images/damla.gif"}
              alt="soho demir organik"
            ></img>
          </div>
        </div> */}

        <section className="section-banner padding-section-about parallax1 ">
          <div className="overlay-banner" />
          <div className="container p-0">
            <div className="row">
              <div className="col-md-12 p-0 col-sm-12">
                <div className="tf-banner center wow fadeInUp">
                  <div className="title-banner">
                    {props.strings["hakkimizda_slider_text"]}
                    <br /> {props.strings["hakkimizda_slider_text_2"]}
                    <br /> {props.strings["hakkimizda_slider_text_3"]}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <a href="#" id="scroll-top" className="scroll-top">
        <i className="fa fa-angle-up" />
      </a>
    </>
  );
};

DashAbout.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(DashAbout));
