import React from "react";
import products_en from "./product_en.json";
import Slider from "react-slick";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
const CarouselComponent = (props) => {
  const ProductCard = (props) => {
    return (
      <>
        <div className="row">
          <div className="d-flex justify-content-center">
            <Card style={{ width: "inherit" }}>
              <a href={`/products/${props.nameSrc}`}>
                <Card.Img variant="top" src={props.imgSrc} />
              </a>
              <Card.Body>
                <Card.Title>
                  <h6 style={{ color: "white" }}>{props.nameSrc}</h6>
                </Card.Title>
                <Card.Text style={{ color: "white" }}>
                  {props.infoSrc}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </>
    );
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: "true",
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <>
      <div className="d-flex justify-content-center">
        <Slider {...settings}>
          {products_en.map((product_en, index) => {
            return (
              <div className="d-flex justify-content-center mb-4">
                <div className="d-flex justify-content-center" key={index}>
                  <ProductCard
                    imgSrc={product_en.img}
                    nameSrc={product_en.name}
                    infoSrc={
                      props.currentLanguageCode == "tr"
                        ? product_en.info
                        : product_en.info_en
                    }
                  />
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

CarouselComponent.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(CarouselComponent));
