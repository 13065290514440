import React, { useState } from "react";

function Loader(props) {
  if (props.loading === true) {
    return (
      <div className="loading">
        <div className="bubble" />
        <div className="bubble" />
        <div className="bubble" />
      </div>
    );
  } else {
    return (
      <>
        <div className="doneLoadingBG" />
        <div className="doneLoadingLeft" />
        <div className="doneLoadingRight" />
      </>
    );
  }
}

export default Loader;
